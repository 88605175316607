// extracted by mini-css-extract-plugin
export var sliderContainer = "home-module--slider-container--3xAF9";
export var slide = "home-module--slide--2r2f0";
export var slideContent = "home-module--slide-content--yUPvq";
export var slideHeader = "home-module--slide-header--3nu2i";
export var slideDescription = "home-module--slide-description--3P0UN";
export var swiperLink = "home-module--swiperLink--G-Htc";
export var socialContainer = "home-module--socialContainer--1Lye2";
export var socialLogo = "home-module--socialLogo--3Q8LQ";
export var ourBrandsContainer = "home-module--ourBrandsContainer--146ew";
export var brandsLogosContainer = "home-module--brandsLogosContainer--1XxCn";
export var brandsLogosWrapper = "home-module--brandsLogosWrapper--3f-0p";
export var circlesContainer = "home-module--circlesContainer--UcSUC";
export var circleContainer = "home-module--circleContainer--2qFy2";
export var circleLink = "home-module--circleLink--3PKLc";
export var sliderWrapper = "home-module--sliderWrapper--3JnYZ";
export var sliderContent = "home-module--slider-content--2Dtcs";
export var sliderInnerContent = "home-module--sliderInnerContent--1SrYu";
export var sliderImageContainer = "home-module--slider-image-container--1hiCF";
export var sliderHeader = "home-module--sliderHeader--iLs0x";
export var sliderDescription = "home-module--sliderDescription--3aREO";
export var sliderBpPrice = "home-module--sliderBpPrice--2O9dI";
export var sliderFpPrice = "home-module--sliderFpPrice--3cCNV";
export var sliderLink = "home-module--sliderLink--31IEp";
export var sliderArrowsContainer = "home-module--sliderArrowsContainer--3tIBV";