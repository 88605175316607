import { StaticImage } from 'gatsby-plugin-image';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { IconContext } from 'react-icons';
import { IoArrowForwardCircleOutline } from 'react-icons/io5';

import Container from 'components/Container/Container';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';

import {
  section,
  container,
  outerContainer,
  text,
  form,
  inputContainer,
  input,
  btn,
  successText,
} from './Newsletter.module.scss';
import { mainHeaderDark } from 'styles/components/typography.module.scss';

const Newsletter = () => {
  const [response, setResponse] = useState(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = async ({ email }) => {
    const response = await addToMailchimp(email);
    setResponse(response);
  };
  return (
    <div className={section}>
      <Container isContent>
        <div className={outerContainer}>
          <StaticImage
            quality={100}
            layout="constrained"
            src="../../images/newsletter.png"
            alt="Newsletter image"
          />
          <div className={container}>
            <h2 className={mainHeaderDark}>Newsletter</h2>
            <p className={text}>
              Εγγραφείτε στο newsletter μας για να ενημερώνεστε για νέες
              κυκλοφορίες και μοναδικές εκπτώσεις
            </p>
            <form onSubmit={handleSubmit(onSubmit)} className={form}>
              <div className={inputContainer}>
                <input
                  type="text"
                  className={input}
                  aria-label="Newsletter email"
                  placeholder="Διεύθυνση Email"
                  {...register('email', {
                    required: 'Το πεδίο είναι υποχρεωτικό',
                    pattern: {
                      value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                      message: 'Λάθος μορφή email',
                    },
                  })}
                />
                <button className={btn} type="submit">
                  <IconContext.Provider
                    value={{ color: '#f55a82', size: '2rem' }}
                  >
                    <IoArrowForwardCircleOutline />
                  </IconContext.Provider>
                </button>
              </div>
              {errors.email && (
                <ErrorMessage>{errors.email.message}</ErrorMessage>
              )}
              {response && response.result === 'success' && (
                <p
                  className={successText}
                  dangerouslySetInnerHTML={{ __html: response.msg }}
                />
              )}
              {response && response.result === 'error' && (
                <ErrorMessage>
                  <span dangerouslySetInnerHTML={{ __html: response.msg }} />
                </ErrorMessage>
              )}
            </form>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Newsletter;
