import * as React from 'react';

function SvgMeetOurBrandsBottom(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 205.8" {...props}>
      <path
        d="M0 0v205.8c302-222 1444 104 1920-190V0H0z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#fed88f"
      />
    </svg>
  );
}

export default SvgMeetOurBrandsBottom;
