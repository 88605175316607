import * as React from 'react';

function SvgMeetOurBrandsTop(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 151.2" {...props}>
      <path
        d="M0 48.8v102.4h1920V48.8C1382 305.3 525.5-141.7 0 48.8z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#fed88f"
      />
    </svg>
  );
}

export default SvgMeetOurBrandsTop;
