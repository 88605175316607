import { useStaticQuery, graphql, Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { IconContext } from 'react-icons';
import { IoLogoFacebook, IoLogoInstagram } from 'react-icons/io5';
import SwiperCore, {
  Autoplay,
  Pagination,
  Keyboard,
  A11y,
  EffectFade,
  Navigation,
} from 'swiper';
import 'swiper/components/pagination/pagination.min.css';
import { Swiper, SwiperSlide } from 'swiper/react';

import Container from 'components/Container/Container';
import HeartsSales from 'components/Icons/Project/HeartsSales';
import MeetOurBrandsBottom from 'components/Icons/Project/MeetOurBrandsBottom';
import MeetOurBrandsTop from 'components/Icons/Project/MeetOurBrandsTop';
import Image from 'components/Image';
import Instagram from 'components/Instagram/Instagram';
import Layout from 'components/Layout';
import Newsletter from 'components/Newsletter/Newsletter';
import OffersCarousel from 'components/OffersCarousel/OffersCarousel';
import Price from 'components/Price/Price';
import PromoSectionCentered from 'components/PromoSection/PromoSectionCentered';
import Seo from 'components/Seo';
import TitleBrand from 'components/TitleBrand/TitleBrand';

import useCarousel from 'data/useCarousel';

import 'swiper/swiper.scss';

import 'swiper/components/a11y/a11y.scss';
import 'swiper/components/navigation/navigation.scss';

import { btnLink } from 'styles/components/links.module.scss';
import { mainHeaderDark } from 'styles/components/typography.module.scss';
import * as styles from 'styles/pages/home.module.scss';

import 'styles/overrides/swiper-pagination.scss';
import 'styles/overrides/swiper-sliders.scss';
import 'styles/overrides/swiper.scss';

// install Swiper modules
SwiperCore.use([Autoplay, Navigation, Pagination, Keyboard, A11y, EffectFade]);

const displayBlock = { display: 'block' };
const brandsTop = { display: 'block', marginTop: '3rem', marginBottom: '-1px' };
const relativePosition = { position: 'relative' };

const IndexPage = () => {
  let {
    allStrapiPromoBlock: { nodes: blocks },
    site: { siteMetadata: info },
    allStrapiCategory: { nodes: categories },
  } = useStaticQuery(query);
  const { carousel } = useCarousel();
  const { carousel: sliders } = useCarousel('/sliders'); // favourite items
  const { carousel: carouselBottom } = useCarousel('/carousel-bottoms'); // sales

  return (
    <Layout fullWidth negative>
      <Seo title="Studio 83, Nolah, Madame shou shou, ANANKE" />
      <div className={styles.sliderContainer}>
        <Swiper
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          speed={500}
          loop={true}
          spaceBetween={0}
          pagination={{
            clickable: true,
          }}
          keyboard
          scrollbar={{ draggable: true }}
          className="swiper-container-full"
        >
          {blocks.map((block) => (
            <SwiperSlide
              key={block.id}
              className={styles.slide}
              style={{
                backgroundImage: `url(${
                  process.env.GATSBY_API_URL + block.image[0].url
                })`,
              }}
            >
              <Link className={styles.swiperLink} to={block.relativePath}>
                <div className={styles.slideContent}>
                  <h1 className={styles.slideHeader}>{block.title}</h1>
                  <p className={styles.slideDescription}>{block.description}</p>
                </div>
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className={styles.socialContainer}>
          <IconContext.Provider value={{ color: '#000' }}>
            <a
              href={info.fb}
              target="_blank"
              rel="noreferrer"
              className={styles.socialLogo}
              aria-label={`${info.title} facebook page`}
            >
              <IoLogoFacebook size="2rem" />
            </a>
            <a
              href={info.instagram}
              target="_blank"
              rel="noreferrer"
              className={styles.socialLogo}
              aria-label={`${info.title} instagram account`}
            >
              <IoLogoInstagram size="2rem" />
            </a>
          </IconContext.Provider>
        </div>
      </div>
      <Container>
        <OffersCarousel
          title={<TitleBrand text="New arrivals" />}
          products={carousel}
        />
      </Container>
      <Container withPadding>
        <Swiper
          freeMode={true}
          spaceBetween={24}
          slidesPerView={'auto'}
          className={styles.circlesContainer}
        >
          {categories.map((category) => (
            <SwiperSlide key={category.id} className={styles.circleContainer}>
              <Link to={`/${category.slug}/`} className={styles.circleLink}>
                {category.title}
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
      </Container>
      <Container isContent withPadding>
        <div style={relativePosition}>
          <Swiper
            autoplay={{
              delay: 15000,
              disableOnInteraction: false,
            }}
            speed={500}
            loop={true}
            spaceBetween={0}
            allowTouchMove={false}
            keyboard
            autoHeight={true}
            navigation={{
              nextEl: '.swiper-slider-next',
              prevEl: '.swiper-slider-prev',
            }}
          >
            {sliders &&
              sliders.map((slider) => (
                <SwiperSlide key={slider._id}>
                  <div className={styles.sliderWrapper}>
                    <div className={styles.sliderContent}>
                      <h1 className={mainHeaderDark}>Favourite items</h1>
                      <div className={styles.sliderInnerContent}>
                        <h1 className={styles.sliderHeader}>{slider.title}</h1>
                        <p className={styles.sliderDescription}>
                          {slider.description}
                        </p>
                        <Price
                          fpClass={styles.sliderFpPrice}
                          bpClass={styles.sliderBpPrice}
                          beginPrice={slider.beginPrice}
                          finalPrice={slider.finalPrice}
                        />
                        <Link
                          className={`${btnLink} ${styles.sliderLink}`}
                          to={slider.relativePath}
                        >
                          ΔΕΙΤΕ ΤΟ
                        </Link>
                      </div>
                      <div className={styles.sliderArrowsContainer}></div>
                    </div>
                    <div className={styles.sliderImageContainer}>
                      <Image
                        filename={slider.images[0].hash}
                        alt={slider.images[0].alternativeText || slider.title}
                      />
                    </div>
                  </div>
                </SwiperSlide>
              ))}
          </Swiper>
          <div className="swiper-slider-prev"></div>
          <div className="swiper-slider-next"></div>
        </div>
      </Container>
      <MeetOurBrandsTop style={brandsTop} />
      <div className={styles.ourBrandsContainer}>
        <Container isContent withPadding>
          <h1 className={mainHeaderDark}>Meet our brands</h1>
          <div className={styles.brandsLogosContainer}>
            <div className={styles.brandsLogosWrapper}>
              <Link
                to="/proionta?brand=STUDIO%2083"
                aria-label="Δείτε προϊόντα Studio 83"
              >
                <StaticImage
                  quality={100}
                  layout="fixed"
                  width={240}
                  src="../images/studio83.png"
                  alt="Studio 83 logo - Clothing for the wanderers"
                />
              </Link>
            </div>
            <div className={styles.brandsLogosWrapper}>
              <Link
                to="/proionta?brand=MADAME SHOU SHOU"
                aria-label="Δείτε προϊόντα MADAME SHOU SHOU"
              >
                <StaticImage
                  quality={100}
                  layout="fixed"
                  width={180}
                  src="../images/madame.png"
                  alt="Madame shou shou logo"
                />
              </Link>
            </div>
            <div className={styles.brandsLogosWrapper}>
              <Link
                to="/proionta?brand=NOLAH%20"
                aria-label="Δείτε προϊόντα Nolah"
              >
                <StaticImage
                  quality={100}
                  layout="fixed"
                  width={180}
                  src="../images/nolah.png"
                  alt="Nolah logo"
                />
              </Link>
            </div>
            <div className={styles.brandsLogosWrapper}>
              <Link
                to="/proionta?brand=ANANKE"
                aria-label="Δείτε προϊόντα ANANKE"
              >
                <StaticImage
                  quality={100}
                  layout="fixed"
                  width={220}
                  src="../images/ananke.png"
                  alt="ANANKE logo"
                />
              </Link>
            </div>
            <div className={styles.brandsLogosWrapper}>
              {/* <Link
                to="/proionta?brand=OFILIA%27S"
                aria-label="Δείτε προϊόντα Ofilia's"
              >
                <StaticImage
                  quality={100}
                  layout="fixed"
                  width={180}
                  src="../images/ofilias.png"
                  alt="ofilias logo"
                />
              </Link> */}
              <Link
                to="/proionta?brand=MAIRIS%20"
                aria-label="Δείτε προϊόντα MAIRIS"
              >
                <StaticImage
                  quality={100}
                  layout="fixed"
                  width={180}
                  src="../images/mairis.png"
                  alt="MAIRIS logo"
                />
              </Link>
            </div>
          </div>
        </Container>
      </div>
      <MeetOurBrandsBottom style={displayBlock} />
      <Container>
        <OffersCarousel
          icon={<HeartsSales width={64} />}
          title={<TitleBrand text="Sales up to -60%" />}
          products={carouselBottom}
        />
      </Container>
      <PromoSectionCentered>
        <Newsletter />
      </PromoSectionCentered>
      <Instagram />
    </Layout>
  );
};

export default IndexPage;

const query = graphql`
  query {
    allStrapiPromoBlock(sort: { fields: itemIndex, order: ASC }) {
      nodes {
        category {
          id
          slug
        }
        id
        title
        relativePath
        image {
          alternativeText
          caption
          url
          hash
        }
      }
    }
    allStrapiCategory(
      filter: { enabled: { eq: true }, visibleToMainMenu: { eq: true } }
      sort: { fields: mainMenuIndex, order: ASC }
    ) {
      nodes {
        title
        slug
        id
        strapiParent {
          id
        }
      }
    }
    site {
      siteMetadata {
        title
        fb
        instagram
      }
    }
  }
`;
