import * as React from 'react';

function SvgHeartsSales(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 76 59" {...props}>
      <path
        d="M49.2 50.9c1-1.1 2-2.1 2.9-3.2 2.9-3.1 6-6.2 9-9.2 4.3-4.3 9.5-8.5 12.6-13.7 2.7-4.6 4-13.2-1.6-16.5-4.8-2.8-9.3-.6-12.7 2.9-1-6-5-10.7-12.2-11.2-5.9-.4-9 3.3-9.7 7.8-5.9-1.9-12.9 6-15 12.2-8-5.8-21.5-6.5-22.5 6-.4 5.3 5.5 10.2 9 13.2 7.2 6.2 14.7 11 20.3 18.9.4.6 1 .8 1.6.8.9.2 2.1-.2 2.5-1.3 2.8-7.8 5.4-15.6 8.2-23.3 1.1 5.9 2 11.9 2.2 18h.1c0 .4.1.7.2 1 1 1.7 3.8.9 3.7-1.1v-.5c.5 1 .1.7 1.4-.8zM47.4 46c-.6-5.7-1.6-11.4-2.8-17-.1-.6-.5-1-.9-1.2 1.4-5.3 1.3-10.5-1.7-15.7-.2-.4-.5-.7-.7-1.1-1.1-10 15.9-8.4 14.2 5.2-.3 2.2 2.4 3.4 3.7 1.4 2.1-3.3 6.8-9.2 11.3-5.5 3.4 2.8.9 9.9-1.4 12.5-3.1 3.5-6.6 6.7-9.8 10.1-1.5 1.6-7.8 6.6-11.9 11.3z"
        fill="#f55a82"
      />
    </svg>
  );
}

export default SvgHeartsSales;
